export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/user/detail/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Profil Saya',
      breadcrumb: [
        {
          text: 'Profil Saya',
          active: true,
        },
      ],
    },
  }
]