export default [
  {
    path: '/pemrek-octo-verify',
    name: 'pemrek-octo-verify',
    component: () => import('@/views/pemrek-octo-verify/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Bukti Pemrek Octo Pay',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Octo Pay',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek-octo-verify/add',
    name: 'pemrek-octo-verify-add',
    component: () => import('@/views/pemrek-octo-verify/create/index.vue'),
    meta: {
      pageTitle: 'Bukti Pemrek Baru',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Octo Pay',
          to: '/pemrek-octo-verify'
        },
        {
          text: 'Bukti Pemrek Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek-octo-verify/detail',
    name: 'pemrek-octo-verify-detail',
    component: () => import('@/views/pemrek-octo-verify/detail/index.vue'),
    meta: {
      pageTitle: 'Bukti Pemrek Baru',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Octo Pay',
          to: '/pemrek-octo-verify'
        },
        {
          text: 'Bukti Pemrek Octo Pay',
          active: true,
        },
      ],
    },
  },
  
]