export default [
  {
    path: '/merchant-doku',
    name: 'merchant-doku',
    component: () => import('@/views/merchant-doku/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Bukti Akuisisi Merchant DOKU',
      breadcrumb: [
        {
          text: 'Bukti Akuisisi Merchant DOKU',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant-doku/add',
    name: 'merchant-doku-add',
    component: () => import('@/views/merchant-doku/create/index.vue'),
    meta: {
      pageTitle: 'Bukti Akuisisi Merchant DOKU Baru',
      breadcrumb: [
        {
          text: 'Bukti Akuisisi Merchant DOKU',
          to: '/merchant-doku'
        },
        {
          text: 'Bukti Akuisisi Merchant DOKU Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant-doku/detail',
    name: 'merchant-doku-detail',
    component: () => import('@/views/merchant-doku/detail/index.vue'),
    meta: {
      pageTitle: 'Bukti Akuisisi Merchant DOKU',
      breadcrumb: [
        {
          text: 'Bukti Akuisisi Merchant DOKU',
          to: '/merchant-doku'
        },
        {
          text: 'Bukti Akuisisi Merchant DOKU',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant-doku-activation/add',
    name: 'merchant-doku-activation-add',
    component: () => import('@/views/merchant-doku-activation/create/index.vue'),
    meta: {
      pageTitle: 'Bukti Aktivasi Merchant DOKU',
      breadcrumb: [
        {
          text: 'Bukti Akuisisi Merchant DOKU',
          to: '/merchant-doku'
        },
        {
          text: 'Bukti Aktivasi Merchant DOKU',
          active: true,
        },
      ],
    },
  },
  
]