export default [
  {
    path: '/policies',
    name: 'policies',
    component: () => import('@/views/policy/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Polis Saya',
      breadcrumb: [
        {
          text: 'Polis Saya',
          active: true,
        },
      ],
    },
  },
  {
    path: '/policy/:id/detail',
    name: 'policy',
    component: () => import('@/views/policy/detail/index.vue'),
    props: true,
    meta: {
      breadcrumb: [
        {
          text: 'Polis Saya',
          to: '/policies'
        },
        {
          text: 'Detail Polis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/policy/add',
    name: 'policy-add',
    component: () => import('@/views/policy/create/index.vue'),
    meta: {
      pageTitle: 'Polis Baru',
      breadcrumb: [
        {
          text: 'Polis Saya',
          to: '/policies'
        },
        {
          text: 'Polis Baru',
          active: true,
        },
      ],
    },
  },
]