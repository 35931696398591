export default [
  {
    path: '/pemrek',
    name: 'pemrek',
    component: () => import('@/views/pemrek/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Nasabah Saya',
      breadcrumb: [
        {
          text: 'Nasabah Saya',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek/detail',
    name: 'pemrek-detail',
    component: () => import('@/views/pemrek/detail/index.vue'),
    props: true,
    meta: {
      breadcrumb: [
        {
          text: 'Nasabah Saya',
          to: '/pemrek'
        },
        {
          text: 'Detail Nasabah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek/add',
    name: 'pemrek-add',
    component: () => import('@/views/pemrek/create/index.vue'),
    meta: {
      pageTitle: 'Nasabah Baru',
      breadcrumb: [
        {
          text: 'Nasabah Saya',
          to: '/pemrek'
        },
        {
          text: 'Nasabah Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek-verify',
    name: 'pemrek-verify',
    component: () => import('@/views/pemrek-verify/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Bukti Pemrek Danamon',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Danamon',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek-verify/add',
    name: 'pemrek-verify-add',
    component: () => import('@/views/pemrek-verify/create/index.vue'),
    meta: {
      pageTitle: 'Bukti Pemrek Baru',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Danamon',
          to: '/pemrek-verify'
        },
        {
          text: 'Bukti Pemrek Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek-verify/detail',
    name: 'pemrek-verify-detail',
    component: () => import('@/views/pemrek-verify/detail/index.vue'),
    meta: {
      pageTitle: 'Bukti Pemrek Baru',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Danamon',
          to: '/pemrek-verify'
        },
        {
          text: 'Bukti Pemrek',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pemrek-activation/add',
    name: 'pemrek-activationadd',
    component: () => import('@/views/pemrek-activation/create/index.vue'),
    meta: {
      pageTitle: 'Bukti Pemrek Baru',
      breadcrumb: [
        {
          text: 'Bukti Pemrek Danamon',
          to: '/pemrek-verify'
        },
        {
          text: 'Bukti Aktivasi',
          active: true,
        },
      ],
    },
  },
]