import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import auth from './routes/auth'
import dashboard from './routes/dashboard'
import policy from './routes/policy'
import claim from './routes/claim'
import profile from './routes/profile'
import pemrek from './routes/pemrek'
import merchant from './routes/merchant'
import pemrekAladin from './routes/pemrek-aladin'
import adira from '@/router/routes/adira'
import pemrekMandiri from './routes/pemrek-mandiri'
import merchantDoku from './routes/merchant-doku'
import pemrekOcto from './routes/pemrek-octo'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
      ...auth,
      ...dashboard,
      ...policy,
      ...claim,
      ...profile,
      ...pemrek,
      ...merchant,
      ...pemrekAladin,
      ...pemrekMandiri,
      ...merchantDoku,
      ...adira,
      ...pemrekOcto,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
