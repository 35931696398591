export default [
  {
    path: '/claims',
    name: 'claims',
    component: () => import('@/views/claim/index.vue'),
    meta: {
      pageTitle: 'Klaim Saya',
      breadcrumb: [
        {
          text: 'Klaim Saya',
          active: true,
        },
      ],
    },
  },
  {
    path: '/claim',
    name: 'claim',
    component: () => import('@/views/claim/detail/index.vue'),
    meta: {
      pageTitle: 'Detail Klaim',
      breadcrumb: [
        {
          text: 'Klaim Saya',
          to: '/claims'
        },
        {
          text: 'Detail Klaim',
          active: true,
        },
      ],
    },
  },
  {
    path: '/claim/add',
    name: 'claim-add',
    component: () => import('@/views/claim/create/index.vue'),
    meta: {
      pageTitle: 'Klaim Baru',
      breadcrumb: [
        {
          text: 'Klaim Saya',
          to: '/claims'
        },
        {
          text: 'Klaim Baru',
          active: true,
        },
      ],
    },
  }
]