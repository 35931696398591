export default [
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('@/views/merchant/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Merchant Saya',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/merchant/:id/detail',
  //   name: 'merchant-detail',
  //   component: () => import('@/views/merchant/detail/index.vue'),
  //   props: true,
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Merchant Saya',
  //         to: '/merchant'
  //       },
  //       {
  //         text: 'Detail Merchant',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/merchant/add',
    name: 'merchant-add',
    component: () => import('@/views/merchant/add/merchant/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/profile',
    name: 'merchant-add-profile',
    component: () => import('@/views/merchant/add/profile/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/bank',
    name: 'merchant-add-bank',
    component: () => import('@/views/merchant/add/bank/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/ktp',
    name: 'merchant-add-ktp',
    component: () => import('@/views/merchant/add/ktp/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/location',
    name: 'merchant-add-location',
    component: () => import('@/views/merchant/add/location/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/form',
    name: 'merchant-add-form',
    component: () => import('@/views/merchant/add/form/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/terms',
    name: 'merchant-add-terms',
    component: () => import('@/views/merchant/add/terms/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/npwp',
    name: 'merchant-add-npwp',
    component: () => import('@/views/merchant/add/npwp/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/soft-qr',
    name: 'merchant-add-soft-qr',
    component: () => import('@/views/merchant/add/soft-qr/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/add/starter-pack',
    name: 'merchant-add-starter-pack',
    component: () => import('@/views/merchant/add/starter-pack/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Merchant Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/merchant/detail',
    name: 'merchant-detail',
    component: () => import('@/views/merchant/detail/index.vue'),
    meta: {
      pageTitle: 'Merchant Baru',
      breadcrumb: [
        {
          text: 'Merchant Saya',
          to: '/merchant'
        },
        {
          text: 'Detail Merchant',
          active: true,
        },
      ],
    },
  }
]