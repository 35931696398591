export default [
  {
    path: '/adira/loan',
    name: 'adira-loan',
    component: () => import('@/views/adira/index.vue'),
    props: true,
    meta: {
      pageTitle: 'Pengajuan Pinjaman',
      breadcrumb: [
        {
          text: 'Pengajuan Pinjaman',
          active: true,
        },
      ],
    },
  },
  {
    path: '/adira/loan/add',
    name: 'adira-loan-add',
    component: () => import('@/views/adira/create/index.vue'),
    meta: {
      pageTitle: 'Pengajuan Baru',
      breadcrumb: [
        {
          text: 'Pengajuan Pinjaman',
          to: '/adira/loan'
        },
        {
          text: 'Pengajuan Pinjaman Baru',
          active: true,
        },
      ],
    },
  },
  {
    path: '/adira/loan/detail',
    name: 'adira-loan-detail',
    component: () => import('@/views/adira/detail/index.vue'),
    meta: {
      pageTitle: 'Detail Pengajuan',
      breadcrumb: [
        {
          text: 'Pengajuan Pinjaman',
          to: '/adira/loan'
        },
        {
          text: 'Detail Pengajuan',
          active: true,
        },
      ],
    },
  }
]